.container {
    height: 102px;
    cursor: text;

    &.has-focus,
    &.has-value {
        .content {
            border-color: var(--color-base);
        }

        .label {
            font-size: 10px;
            top: 6px;
            color: var(--color-text-base);
            font-weight: bold;
        }

        .input {
            opacity: 1;
        }
    }

    &.has-error {
        .content {
            border-color: var(--color-red);
        }

        .label {
            color: var(--color-red);
        }
    }

    &.theme-negative {
        .content {
            border-color: var(--color-white);
        }

        .label {
            color: var(--color-white);
        }

        .input {
            color: var(--color-white);

            &::placeholder {
                color: rgba(255, 255, 255, .7);
            }
        }

        &.has-error {
            .error {
                color: var(--color-white);
                font-style: italic;
            }
        }
    }

    &.disabled {
        opacity: .6;
        &, & * {
            pointer-events: none;
        }
    }
}

.content {
    border: 1px solid var(--color-grey-platinum);
    border-radius: 4px;
    position: relative;
    text-align: left;
}

.label {
    font-size: 14px;
    position: absolute;
    top: 14px;
    left: 12px;
    color: var(--color-grey-dark);
    pointer-events: none;
    transition: all ease .2s;
}

.input {
    width: 100%;
    height: 60px;
    margin: 20px 0 0;
    padding: 0 12px 0;
    border: 0;
    outline: 0;
    font-size: 14px;
    color: var(--color-grey-dark);
    letter-spacing: .4px;
    opacity: 0;
    transition: opacity ease .2s;
    background: transparent;
    font-family: inherit;
    resize: none;

    &::placeholder {
        color: var(--color-grey-light);
    }
}

.error {
    font-size: 12px;
    color: var(--color-red);
    font-weight: bold;
    margin: 2px 0 0;
    height: 14px;
}
