.container {
    padding: 24px 0;
    background: var(--color-base-alt);
    color: #fff;
}

.social {
    display: flex;
    justify-content: flex-end;

    .social-item-wrap {
        &:not(:last-child) {
            margin: 0 16px 0 0;
        }
    }

    .social-item {
        padding: 8px;
        width: 46px;
        height: 46px;
        cursor: pointer;
        transition: all ease .3s;

        &:hover {
            opacity: .8;
        }
    }
}

.split {
    height: 1px;
    background: var(--color-white);
    margin: 24px 0;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .logo {
        flex: 1;
        height: 53px;
        background: url(/img/global/logo-white.svg) left top no-repeat;
    }

    .phone {
        flex: 1;
        font-size: 32px;
        text-align: center;

        span {
            cursor: pointer;
            transition: opacity ease .2s;

            &:hover {
                opacity: .8;
            }
        }
    }

    .address {
        flex: 1;
        font-size: 20px;
        line-height: 28px;
        text-align: right;
    }
}

.bottom {
    background: #3d767d;
    color: var(--color-white);
    padding: 16px 0;

    .bottom-holder {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .copyright {
        font-size: 14px;
    }

    .vbases {
        transition: opacity ease .2s;

        &:hover {
            opacity: .8;
        }

        .vbases-logo {
            width: 100px;
            height: 40px;
            background: url(/img/global/vbases.svg) center no-repeat;
            background-size: 82px;
        }
    }
}

@media(max-width: 768px) {
    .social {
        justify-content: center;
    }

    .content {
        display: block;

        .logo {
            background-position: center;
        }

        .phone {
            margin: 24px 0 0;
            font-size: 24px;
        }

        .address {
            margin: 24px 0 0;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
        }
    }

    .bottom {
        .bottom-holder {
            flex-direction: row-reverse;
        }
        
        .copyright {
            font-size: 12px;
        }
    }
}
