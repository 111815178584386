.container {
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: 100000;
    transition: all ease .3s;
    border-radius: 50%;

    &:hover {
        box-shadow: 0 0 6px 1px rgba(0, 0, 0, .4);
    }
}

.icon {
    width: 50px;
    height: 50px;
    background: #4dc247;
    background-size: 28px;
    border-radius: 50%;
    color: #fff;
    padding: 8px;
}
