.container {
    margin: 24px 0 0;
    background: var(--gradient-base);
    border-radius: 8px;
    padding: 24px;
    position: relative;

    .row {
        display: flex;

        &:not(:first-child) {
            margin: 16px 0 0;
        }

        .row-item {
            flex: 1;

            &:not(:first-child) {
                margin: 0 0 0 24px;
            }
        }
    }

    .button {
        display: flex;
        justify-content: flex-end;
    }

    .result {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 8px;
        background: var(--gradient-base);
        padding: 24px;

        .result-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--color-white);

            .result-icon {
                width: 64px;
                height: 64px;
                border-radius: 50%;
                border: 2px solid var(--color-white);
                padding: 20px;
            }

            .result-title {
                margin: 24px 0 0;
                font-size: 20px;
                font-weight: bold;
            }

            .result-text {
                max-width: 80%;
                margin: 24px auto 0;
                text-align: center;
            }

            .result-details {
                margin: 16px 0 0;
                font-size: 12px;
            }

            .result-button {
                margin: 24px 0 0;
            }
        }
    }
}
