.backdrop {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 20;
}

.backdrop.open {
    display: block;
    animation: show .3s ease-in 1;
}

.backdrop.is-closing {
    display: block;
    animation: close .3s ease-in 1;
}

.container {
    background: #fff;
    position: fixed;
    top: 0;
    right: 0;
    width: 416px;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    z-index: 21;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    min-height: 100vh;
    animation: container-show .5s ease 1;
}

.backdrop.is-closing .container {
    animation: container-close .3s ease 1;
}

.content {
    padding: 24px;
    min-height: 100vh;
    display: flex;

    .content {
        padding: 16px;
    }
}

@media(max-width: 767px) {
    .container {
        width: 80%;
        right: 0;
    }
}

@keyframes show {
    0% {background: rgba(0, 0, 0, 0);}
    100% {background: rgba(0, 0, 0, .5);}
}

@keyframes close {
    0% {background: rgba(0, 0, 0, .5);}
    100% {background: rgba(0, 0, 0, 0);}
}

@keyframes container-show {
    0% {right: -416px;}
    100% {right: 0}
}

@keyframes container-close {
    0% {right: 0;}
    100% {right: -416px}
}

@media(max-width: 767px) {
    @keyframes container-show {
        0% {right: -100%;}
        100% {right: 0}
    }
    
    @keyframes container-close {
        0% {right: 0;}
        100% {right: -100%}
    }
}
