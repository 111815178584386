.container {
    padding: 4px 0;
    background: linear-gradient(to top, #168594, #17a5b8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    width: 78px;
    height: 53px;
    background: url(/img/global/logo-white.svg);
    cursor: pointer;
    transition: opacity ease .2s;

    &:hover {
        opacity: .9;
    }
}

.mobile {
    display: none;
}

.menu {
    color: #fff;

    ul {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;

        li {
            .menu-item {
                padding: 4px 0;
                cursor: pointer;
                font-size: 20px;
                border-bottom: 1px solid transparent;

                &.current {
                    border-color: #fffa;

                    &:hover {
                        border-color: #fffa;
                    }
                }

                &:hover {
                    border-color: #fff6;
                }
            }

            &:not(:last-child) {
                margin-right: 32px;
            }
        }
    }
}

.header-fix {
    height: 61px;
}

.drawer {
    display: none;
}

@media(max-width: 768px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .drawer-button {
        width: 40px;
        height: 40px;
        color: #fff;
        cursor: pointer;
    }

    .drawer {
        display: block;
        width: 100%;

        .drawer-top {
            width: 100%;
            color: var(--grey-dark);
            display: flex;
            justify-content: flex-end;

            .drawer-close {
                width: 32px;
                height: 32px;
                cursor: pointer;
            }
        }

        .drawer-buttons {
            margin: 24px 0 0;

            .drawer-buttons-item {
                & > * {
                    width: 100%;
                }

                &:not(:first-child) {
                    margin: 16px 0 0;
                }
            }
        }

        .drawer-menu {
            border-top: 1px solid var(--color-grey-light);
            margin: 24px 0 0;
            padding: 24px 0 0;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
            }

            .drawer-menu-item {
                padding: 12px 8px;
                margin: 0 0 0 -8px;
                font-size: 20px;
                color: var(--grey-dark);
                position: relative;
                border-radius: 4px;
            }
        }
    }
}
