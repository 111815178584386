.hero {
    .hero-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 400px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        .hero-item-text {
            background: rgba(22, 133, 148, .4);
            color: #fff;
            padding: 48px;
            box-shadow: 0 0 20px rgba(0, 0, 0, .3);
            font-size: 32px;
            max-width: 500px;
            text-align: center;
            font-weight: lighter;
            
            span {
                font-weight: normal;
            }
        }
    }

    .hero-item-implantes {
        background-image: url(/img/pages/home/slider/implantes-desktop.jpg);
    }

    .hero-item-estetica {
        background-image: url(/img/pages/home/slider/estetica-desktop.jpg);
    }

    .hero-item-dtm {
        background-image: url(/img/pages/home/slider/dtm-desktop.jpg);
    }

    .hero-item-checkup {
        background-image: url(/img/pages/home/slider/checkup-desktop.jpg);
    }
}

.solucoes {
    padding: 32px 0;

    .solucoes-holder {
        display: flex;
        align-items: center;
    }

    .solucoes-image {
        width: 373px;
        height: 486px;
        background: url(/img/pages/home/solucoes/image.jpg);
    }

    .solucoes-main {
        margin: 0 0 0 32px;
        flex: 1;
    }

    .solucoes-subtitle {
        margin: 24px 0 0;
    }

    .solucoes-text {
        margin: 24px 0 0;
    }
}

.boxes {
    padding: 32px 0;

    .boxes-holder {
        display: flex;
        justify-content: space-between;
    }

    .boxes-item {
        flex: 1;

        &:not(:last-child) {
            margin: 0 64px 0 0;
        }

        .boxes-item-image {
            height: 170px;
            box-shadow: 0 0 10px rgba(0, 0, 0, .12);
            background-size: cover;
        }

        .boxes-item-title {
            margin: 32px 0 0;
            color: var(--color-text-base);
            font-size: 20px;
            font-weight: bold;
        }

        .boxes-item-text {
            margin: 16px 0 0;
            color: var(--color-grey-dark);
            font-size: 14px;
            line-height: 20px;
        }

        &.boxes-item-implantes {
            .boxes-item-image {
                background-image: url(/img/pages/home/boxes/implantes.jpg);
            }
        }

        &.boxes-item-estetica {
            .boxes-item-image {
                background-image: url(/img/pages/home/boxes/estetica.jpg);
            }
        }

        &.boxes-item-dtm {
            .boxes-item-image {
                background-image: url(/img/pages/home/boxes/dtm.jpg);
            }
        }

        &.boxes-item-checkup {
            .boxes-item-image {
                background-image: url(/img/pages/home/boxes/checkup.jpg);
            }
        }
    }
}

.info {
    padding: 32px 0;
    
    .info-holder {
        display: flex;
        align-items: center;
    }

    .convenios {
        flex: 1;

        .convenios-title {
            text-align: center;
        }

        .convenios-list {
            margin: 24px 0 0;
            display: grid;
            grid-template-columns: auto auto;
            grid-template-rows: 150px 150px;
            gap: 32px;

            .convenios-list-item {
                border: 1px solid var(--color-grey-platinum);
                background-position: center;
                background-repeat: no-repeat;

                &.amil {background-image: url(/img/convenios/amil.png);}
                &.unimed {background-image: url(/img/convenios/unimed-odonto.png);}
                &.metlife {background-image: url(/img/convenios/metlife.png);}
                &.odonto {background-image: url(/img/convenios/odonto-empresas.png);}
            }
        }
    }

    .contato {
        flex: 1;
        margin: 0 0 0 32px;

        .contato-title {
            text-align: center;
        }
    }
}

.social {
    padding: 32px 0;
    
    .social-holder {
        display: flex;
        align-items: center;
    }
    
    .social-doctoralia {
        flex: 1;
        display: flex;
        justify-content: center;
    }

    .social-facebook {
        flex: 1;
        display: flex;
        justify-content: center;
    }
}

@media(max-width: 768px) {
    .hero {
        .hero-slider {
            width: calc(100% + 32px);
            margin: 0 0 0 -16px;
        }

        .hero-item {
            height: 250px;
            background-position: right center;
            background-size: cover;

            .hero-item-text {
                font-size: 24px;
                width: 80%;
                padding: 24px;
            }

            .hero-item-implantes {
                background-image: url(/img/pages/home/slider/implantes-mobile.jpg);
            }
        
            .hero-item-estetica {
                background-image: url(/img/pages/home/slider/estetica-mobile.jpg);
            }
        
            .hero-item-dtm {
                background-image: url(/img/pages/home/slider/dtm-mobile.jpg);
            }
        
            .hero-item-checkup {
                background-image: url(/img/pages/home/slider/checkup-mobile.jpg);
            }
        }
    }

    .solucoes {
        .solucoes-holder {
            display: block;
        }

        .solucoes-image {
            width: 100%;
            height: 200px;
            background: url(/img/pages/home/solucoes/image-mobile.jpg) center;
            background-size: cover;
        }

        .solucoes-main {
            margin: 24px 0 0;
        }
    }

    .boxes {
        .boxes-viewport {
            width: calc(100% + 32px);
            overflow: auto;
            margin: 0 0 0 -16px;
            padding: 16px;
        }

        .boxes-holder {
            width: 300%;
            padding-right: 16px;
        }

        .boxes-item {
            width: 60%;

            &:not(:last-child) {
                margin: 0 32px 0 0;
            }
        }
    }

    .info {
        .info-holder {
            display: block;
        }

        .convenios {
            .convenios-list {
                display: block;

                .convenios-list-item {
                    height: 150px;
                    background-size: 120px;

                    &:not(:first-child) {
                        margin: 24px 0 0;
                    }
                }
            }
        }

        .contato {
            margin: 64px 0 0;
        }
    }

    .social {
        .social-holder {
            display: block;
        }

        .social-facebook {
            margin: 32px 0 0;
        }
    }
}
