.container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    border-radius: 4px;
    background: var(--color-base);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    transition: all ease .2s;
    border: 1px solid transparent;

    &:hover {
        opacity: .9;
    }

    &.is-loading {
        pointer-events: none;
    }

    .icon {
        width: 16px;
        height: 16px;
    }

    .icon + .value {
        margin: 0 0 0 16px;
    }

    .loading {
        position: absolute;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border-radius: 4px;
        top: -1px;
        left: -1px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--color-base);
    }

    &.theme-negative {
        background: var(--color-white);
        
        .value {
            color: var(--color-text-base);
            font-weight: bold;
            font-size: 14px;
        }

        .loading {
            background: var(--color-white);
            color: var(--color-base);
        }
    }
}
